import axios from 'axios'
import { getItem } from './storage'
import { ElMessage } from 'element-plus'
import api from '../api/index'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

service.interceptors.request.use(config => {
  const token = getItem('TOKEN')
  if (token) {
    config.headers['user-token'] = token
  }
  return config
}, error => {
  return Promise.reject(error)
})

service.interceptors.response.use(response => {
  const { code, msg } = response.data
  if (code == 1) {
    return response
  } else {
    ElMessage({
      message: msg,
      type: 'error',
      offset: 80,
      showClose: true
    })
    return response
  }
},
error => {
  ElMessage.error(error.msg)
  return Promise.reject(error)
})

// 封装axios请求方法，参数为配置对象
// option = {method,url,params} method为请求方法，url为请求接口，params为请求参数
async function http(option = {}) {
  let result = null
  if (option.method === 'get' || option.method === 'delete') {
    // 处理get、delete请求
    await service[option.method](
      api[option.url],
      { params: option.params }
    ).then(res => {
      result = res.data
    }).catch(err => {
      result = err
    })
  } else if (option.method === 'post' || option.method === 'put') {
    // 处理post、put请求
    await service[option.method](
      api[option.url],
      option.params
    ).then(res => {
      result = res.data
    }).catch(err => {
      result = err
    })
  }

  return result
}

export default http
// export default service

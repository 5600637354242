export default {
  post5c78dbfd977cf: '/m5453/5c78dbfd977cf', // 密码登录
  post5c78dca45ebc1: '/m5453/5c78dca45ebc1', // 验证码登录
  post5b5bdc44796e8: '/m5453/5b5bdc44796e8', // 发送验证
  post5cad9f63e4f94: '/m5453/5cad9f63e4f94', // 注册账号
  post5caeeba9866aa: '/m5453/5caeeba9866aa', // 忘记密码
  post641568f1b6f87: '/m5453/641568f1b6f87', // 系统页面
  post65def6870ab98: '/m5453/65def6870ab98', // 求职行业
  post65def7d29bc8f: '/m5453/65def7d29bc8f', // 查询签证
  post65def889ed364: '/m5453/65def889ed364', // 查询学历
  post65def8f4839b0: '/m5453/65def8f4839b0', // 语言要求
  post65e1946b7c399: '/m5453/65e1946b7c399', // 发布求职招聘
  post65e196246410e: '/m5453/65e196246410e', // 求职招聘列表
  post661cef251f1ae: '/m5453/661cef251f1ae', // 求职招聘详情
  post65d84dc81a772: '/m5453/65d84dc81a772', // 举报原因
  post65d84d504704f: '/m5453/65d84d504704f', // 举报
  post65e58cd7925d5: '/m5453/65e58cd7925d5', // 房源列表
  post65def9c6161cc: '/m5453/65def9c6161cc', // 出租类型
  post65def9f4b39ab: '/m5453/65def9f4b39ab', // 房屋配置
  post65defa203c570: '/m5453/65defa203c570', // 房源配置
  post65e58a378bd47: '/m5453/65e58a378bd47', // 发布澳洲房源
  post661ceed2da8c0: '/m5453/661ceed2da8c0', // 房源详情
  post65defa6a9b3e1: '/m5453/65defa6a9b3e1', // 车型
  post65defa87dad26: '/m5453/65defa87dad26', // 变速箱
  post65defaab7d31a: '/m5453/65defaab7d31a', // 座位数
  post65e188ad92de6: '/m5453/65e188ad92de6', // 燃油类型
  post65e58fcc08c0a: '/m5453/65e58fcc08c0a', // 车辆列表
  post65e591041bde2: '/m5453/65e591041bde2', // 发布车辆交易
  post65e188fbed6c9: '/m5453/65e188fbed6c9', // 生意行业
  post65e18c6130144: '/m5453/65e18c6130144', // 店铺优势
  post65ee6070e4dfa: '/m5453/65ee6070e4dfa', // 发布人身份
  post65efefcba28bc: '/m5453/65efefcba28bc', // 生意列表
  post65ee6d2ac7f51: '/m5453/65ee6d2ac7f51', // 发布生意
  post65e18cbbd81a3: '/m5453/65e18cbbd81a3', // 交易城市
  post65e18cf209ca7: '/m5453/65e18cf209ca7', // 交易方式
  post65e18d155e150: '/m5453/65e18d155e150', // 保修服务
  post65ee7249bb8d9: '/m5453/65ee7249bb8d9', // 发布交易
  post65eff39bb549e: '/m5453/65eff39bb549e', // 交易列表
  post661e41af99a08: '/m5453/661e41af99a08', // 交易详情
  post661cff5314473: '/m5453/661cff5314473', // 生意详情
  post661cfbf631954: '/m5453/661cfbf631954', // 车辆详情
  post5c78c4772da97: '/m5453/5c78c4772da97', // 会员信息
  post5cb54af125f1c: '/m5453/5cb54af125f1c', // 修改个人信息
  post6414719bdc956: '/m5453/6414719bdc956', // 意见反馈
  post6414724b96f3f: '/m5453/6414724b96f3f', // 提交反馈
  post641472eb35317: '/m5453/641472eb35317', // 历史反馈
  post65f0071839b6e: '/m5453/65f0071839b6e', // 广告投放
  post5f6db4db8abcf: '/m5453/5f6db4db8abcf', // 效验验证码
  post5f6c915d69d1f: '/m5453/5f6c915d69d1f', // 绑定新手机号
  post661cf6635a46c: '/m5453/661cf6635a46c', // 查询我的求职
  post661cf805a900c: '/m5453/661cf805a900c', // 查询我的房源
  post661cf8c60958b: '/m5453/661cf8c60958b', // 查询我的车辆
  post661cfec8b0210: '/m5453/661cfec8b0210', // 查询我的生意
  post661e421b475a6: '/m5453/661e421b475a6', // 查询我的交易
  post65f0025244268: '/m5453/65f0025244268', // 收藏
  post65f0057b3b41e: '/m5453/65f0057b3b41e', // 取消收藏
  post65effe2551a00: '/m5453/65effe2551a00', // 收藏列表
  post661e4cd8e0959: '/m5453/661e4cd8e0959', // 查看评论
  post661f83e4f37d1: '/m5453/661f83e4f37d1', // 发布评论
  post661e3e0b81301: '/m5453/661e3e0b81301', // 删除交易
  post661cffbc1953b: '/m5453/661cffbc1953b', // 删除生意
  post661cfccd71928: '/m5453/661cfccd71928', // 删除车辆
  post661cf22f232a5: '/m5453/661cf22f232a5', // 删除房源
  post661cd28f1d11d: '/m5453/661cd28f1d11d', // 删除求职
  post6421a00e4fa4e: '/m5453/6421a00e4fa4e', // 首页消息
  post64219c5dc8d0d: '/m5453/64219c5dc8d0d', // 首页消息详情
  post662cccd9cd45a: '/m5453/662cccd9cd45a', // 设置平台
  post6621d9dae9b55: '/m5453/6621d9dae9b55', // 律师
  post65d8434577281: '/m5453/65d8434577281', // 律师问题
  post65d84564c7ae0: '/m5453/65d84564c7ae0', // 律师提交
  post65ee750defbfe: '/m5453/65ee750defbfe', // 投资
  post65ee7563f1949: '/m5453/65ee7563f1949', // 投资详情
  post65ee77b6e97b4: '/m5453/65ee77b6e97b4', // 资讯投资
  post661cd65a42151: '/m5453/661cd65a42151', // 编辑求职
  post661cf19591c17: '/m5453/661cf19591c17', // 编辑房源
  post661cfe009b869: '/m5453/661cfe009b869', // 编辑车辆
  post661d01cc13bb4: '/m5453/661d01cc13bb4', // 编辑生意
  psot661e42e626fe1: '/m5453/661e42e626fe1', // 编辑交易
  post641e624160dd0: '/m5453/641e624160dd0', // 轮播图
  post663831e9c9a3f: '/m5453/663831e9c9a3f', // 删除评论
  post6638a09a72cbc: '/m5453/6638a09a72cbc', // 生意其他
  post6653eac17e1ca: '/m5453/6653eac17e1ca', // 服务范围
  post6481977a6298c: '/m5453/6481977a6298c', // oss直传
  post65e18c783172e: '/m5453/65e18c783172e' // 其他配置
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import '@/styles/index.scss'
import 'animate.css'
import http from './utils/request'
// import '@popperjs/core'
// import 'bootstrap'
import installElementPlus from './plugins/element'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
const app = createApp(App)
app.config.globalProperties.$request = http // 方法挂载到全局
app.config.globalProperties.$isTest = '0' // 方法挂载到全局
installElementPlus(app)
app.use(store).use(router).mount('#app')

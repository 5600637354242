import { createRouter, createWebHistory } from 'vue-router'
// import Detail from '@/views/toApply/toApply'

const routes = [
  // pc h5 首页
  {
    path: '/',
    component: () => import('@/views/index'),
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/newDetail/home.vue'),
        meta: {
          title: '首页'
        }
      },
      // pc消息详情
      {
        path: 'newDetail',
        name: 'newDetail',
        component: () => import('@/views/newDetail/newDetail.vue'),
        meta: {
          title: '详情'
        }
      },
      // pc关于我们
      {
        path: 'aboutDetail',
        name: 'aboutDetail',
        component: () => import('@/views/newDetail/aboutDetail.vue'),
        meta: {
          title: '详情'
        }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    component: () => import('@/views/login/register.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/forgetpwd',
    component: () => import('@/views/login/forgetpwd.vue'),
    meta: {
      title: '忘记密码'
    }
  },
  // pc h5 求职招聘
  {
    path: '/toApply',
    component: () => import('@/views/toApply/index.vue'),
    meta: {
      title: '求职招聘'
    },
    redirect: '/toApply/toJob',
    children: [
      {
        path: 'toJob',
        name: 'toJob',
        component: () => import('@/views/toApply/toApply.vue'),
        meta: {
          title: '求职招聘'
        }
      },
      // pc 发布求职招聘
      {
        path: 'addApply',
        name: 'addApply',
        component: () => import('@/views/toApply/addApply.vue'),
        meta: {
          title: '求职招聘',
          needLogin: true
        }
      },
      // pc 发布详情
      {
        path: 'vacancy',
        name: 'vacancy',
        component: () => import('@/views/toApply/vacancy.vue'),
        meta: {
          title: '求职招聘',
          needLogin: true
        }
      }
    ]
  },
  // pc h5 澳洲房源 首页信息
  {
    path: '/housing',
    component: () => import('@/views/housing/index.vue'),
    meta: {
      title: '澳洲房源'
    },
    redirect: '/housing/resource',
    children: [
      {
        path: 'resource',
        name: 'Resource',
        component: () => import('@/views/housing/housing.vue'),
        meta: {
          title: '澳洲房源'
        }
      },
      // pc 发布澳洲房源
      {
        path: 'addHouse',
        name: 'addHouse',
        component: () => import('@/views/housing/addHouse.vue'),
        meta: {
          title: '澳洲房源',
          needLogin: true
        }
      }
    ]
  },
  // pc h5 车辆信息 首页信息
  {
    path: '/dealCap',
    component: () => import('@/views/dealCap/index.vue'),
    meta: {
      title: '车辆交易'
    },
    redirect: '/dealCap/transDeal',
    children: [
      {
        path: 'transDeal',
        name: 'transDeal',
        component: () => import('@/views/dealCap/dealCap.vue'),
        meta: {
          title: '车辆交易'
        }
      },
      // pc 发布车辆交易
      {
        path: 'addDeal',
        name: 'addDeal',
        component: () => import('@/views/dealCap/addDeal.vue'),
        meta: {
          title: '车辆交易',
          needLogin: true
        }
      }
    ]
  },
  // pc h5 生意出售 首页信息
  {
    path: '/business',
    component: () => import('@/views/business/index.vue'),
    meta: {
      title: '生意出售'
    },
    redirect: '/business/nessTop',
    children: [
      {
        path: 'nessTop',
        name: 'nessTop',
        component: () => import('@/views/business/business.vue'),
        meta: {
          title: '生意出售'
        }
      },
      // pc 发布生意出售
      {
        path: 'addBus',
        name: 'addBus',
        component: () => import('@/views/business/addBus.vue'),
        meta: {
          title: '生意出售',
          needLogin: true
        }
      }
    ]
  },
  // pc h5 线下交易 首页信息
  {
    path: '/offline',
    component: () => import('@/views/offline/index.vue'),
    meta: {
      title: '线下交易'
    },
    redirect: '/offline/below',
    children: [
      {
        path: 'below',
        name: 'below',
        component: () => import('@/views/offline/offline.vue'),
        meta: {
          title: '线下交易'
        }
      },
      // pc 发布 线下交易
      {
        path: 'addoff',
        name: 'addoff',
        component: () => import('@/views/offline/addoff.vue'),
        meta: {
          title: '线下交易',
          needLogin: true
        }
      }
    ]
  },
  // pc h5 法律咨询 首页信息
  {
    path: '/legalAdvice',
    component: () => import('@/views/legalAdvice/legalAdvice.vue'),
    meta: {
      title: '法律咨询'
    }
  },
  // pc h5 移民投资 首页信息
  {
    path: '/immigrant',
    component: () => import('@/views/immigrant/immigrant.vue'),
    meta: {
      title: '移民投资'
    }
  },
  // pc 个人中心
  {
    path: '/userInfo',
    component: () => import('@/views/userInfo/index.vue'),
    meta: {
      title: '个人中心'
    },
    redirect: '/userInfo/myInfo',
    children: [
      {
        path: 'myInfo',
        name: 'myInfo',
        component: () => import('@/views/userInfo/myInfo.vue'),
        meta: {
          title: '个人中心',
          needLogin: true
        }
      },
      {
        path: 'issue',
        name: 'issue',
        component: () => import('@/views/userInfo/issue.vue'),
        meta: {
          title: '个人中心',
          needLogin: true
        }
      },
      {
        path: 'collect',
        name: 'collect',
        component: () => import('@/views/userInfo/collect.vue'),
        meta: {
          title: '个人中心',
          needLogin: true
        }
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/views/userInfo/feedback.vue'),
        meta: {
          title: '个人中心',
          needLogin: true
        }
      },
      {
        path: 'advert',
        name: 'advert',
        component: () => import('@/views/userInfo/advert.vue'),
        meta: {
          title: '个人中心',
          needLogin: true
        }
      },
      {
        path: 'password',
        name: 'password',
        component: () => import('@/views/userInfo/password.vue'),
        meta: {
          title: '个人中心',
          needLogin: true
        }
      },
      {
        path: 'mobile',
        name: 'mobile',
        component: () => import('@/views/userInfo/mobile.vue'),
        meta: {
          title: '个人中心',
          needLogin: true
        }
      }
    ]
  },
  // h5 发布成功
  {
    path: '/sucessIuss',
    component: () => import('@/views/toApply/sucessIuss.vue'),
    meta: {
      title: '发布成功'
    }
  },
  // h5 发布详情
  {
    path: '/iussDetail',
    component: () => import('@/views/toApply/iussDetail.vue'),
    meta: {
      title: '求职招聘',
      needLogin: true
    }
  },
  // h5 消息详情
  {
    path: '/MessDetail',
    component: () => import('@/views/newDetail/MessDetail.vue'),
    meta: {
      title: '消息详情'
    }
  },
  // h5 关于我们
  {
    path: '/aboutWeb',
    component: () => import('@/views/newDetail/aboutWeb.vue'),
    meta: {
      title: '关于我们'
    }
  },
  // h5 发布求职招聘
  {
    path: '/addHFive',
    component: () => import('@/views/toApply/addHFive.vue'),
    meta: {
      title: '求职招聘',
      needLogin: true
    }
  },
  // h5 发布澳洲房源
  {
    path: '/addHRoom',
    component: () => import('@/views/housing/addHRoom.vue'),
    meta: {
      title: '澳洲房源',
      needLogin: true
    }
  },
  // h5 发布车辆交易
  {
    path: '/addHCar',
    component: () => import('@/views/dealCap/addHCar.vue'),
    meta: {
      title: '车辆交易',
      needLogin: true
    }
  },
  // h5 发布生意出售
  {
    path: '/addHShop',
    component: () => import('@/views/business/addHShop.vue'),
    meta: {
      title: '生意出售',
      needLogin: true
    }
  },
  // h5 发布线下交易
  {
    path: '/addHBelow',
    component: () => import('@/views/offline/addHBelow.vue'),
    meta: {
      title: '线下交易',
      needLogin: true
    }
  },
  // h5 个人中心
  {
    path: '/myFive',
    component: () => import('@/views/myFive/myFive.vue'),
    meta: {
      title: '个人中心',
      needLogin: true
    }
  },
  // h5 个人资料
  {
    path: '/myInfo',
    component: () => import('@/views/myFive/myInfo.vue'),
    meta: {
      title: '个人资料',
      needLogin: true
    }
  },
  // h5 我的发布
  {
    path: '/publish',
    component: () => import('@/views/myFive/publish.vue'),
    meta: {
      title: '我的发布',
      needLogin: true
    }
  },
  // h5 我的收藏
  {
    path: '/enshrine',
    component: () => import('@/views/myFive/enshrine.vue'),
    meta: {
      title: '我的收藏',
      needLogin: true
    }
  },
  // h5 意见反馈
  {
    path: '/feedback',
    component: () => import('@/views/myFive/feedback.vue'),
    meta: {
      title: '意见反馈',
      needLogin: true
    }
  },
  // h5 反馈记录
  {
    path: '/feedbackdetail',
    component: () => import('@/views/myFive/feedbackdetail.vue'),
    meta: {
      title: '反馈记录',
      needLogin: true
    }
  },
  // h5 广告投放
  {
    path: '/putin',
    component: () => import('@/views/myFive/putin.vue'),
    meta: {
      title: '广告投放',
      needLogin: true
    }
  },
  // h5 修改密码手机号验证
  {
    path: '/changePassword',
    component: () => import('@/views/myFive/changePassword.vue'),
    meta: {
      title: '修改密码',
      needLogin: true
    }
  },
  // h5 修改密码
  {
    path: '/passwordIs',
    component: () => import('@/views/myFive/passwordIs.vue'),
    meta: {
      title: '修改密码',
      needLogin: true
    }
  },
  // h5 修改手机号验证
  {
    path: '/changePhone',
    component: () => import('@/views/myFive/changePhone.vue'),
    meta: {
      title: '修改绑定手机号',
      needLogin: true
    }
  },
  // h5 修改手机号
  {
    path: '/phoneIs',
    component: () => import('@/views/myFive/phoneIs.vue'),
    meta: {
      title: '绑定新手机',
      needLogin: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach(async (to, form, next) => {
  window.scrollTo(0, 0)
})
router.beforeEach(async (to, from, next) => {
  let Token = window.localStorage.getItem('TOKEN')
  if (to.meta.needLogin && !Token) {
    next('/login')
    return
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
